.smartwizard {

    .nav {

        &::before {
            background-color: #dddddd !important;
        }

        > li {
            @media screen and (max-width: 991px){
              display: none;

              &.active {
                display: block;
              }
            }
        }

        .nav-link {
            cursor: pointer;

            &.active {
                color: $primary !important;

                &::after {
                    background-color: $primary !important;
                }
            }

            &::after {
                background-color: $true-white !important;
                border: 8px solid #dddddd !important;
            }
        }
    }

    @media screen and (max-width: 640px) {
        .nav::before {
            top: 18px;
            left: 0;
            width: 100%;
            height: 5px;
        }

        .nav .nav-link::after {
            top: -42px;
            right: 0;
        }

        .nav .nav-link > .num {
            top: -31px;
            right: 0;
            width: 46px;
            padding-top: 0;
        }

        .nav .nav-link {
            margin-top: 40px;
            margin-bottom: 0;
            padding: 0.5rem 1rem;
            text-align: center !important;
        }
    }

    .button-action {
        display: flex;
        justify-content: space-between;
    }
}