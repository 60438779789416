/* Generic palette */
$true-black:#000000;
$true-white:#ffffff;
$form-errors:#a94442;
$form-warning:#f0ad4e;
$form-success:#5cb85c;

$dark-blue: #253c4d;
$cherry: #e5004d;
$green-pale: #bfdd92;
$turquoise: #3edad8;

$black-grey: #303133;
$deeper-grey: #777777;
$deep-grey: #cccccc;
$medium-grey: #e9ebed;
$soft-grey: #707070;
$light-grey: #d2d2d2;
$lighter-grey: #f8f9f9;

$primary: #213a4c;
$secondary: #213a4c;
$tertiary: #03bfee;
$quaternary: $turquoise;
$light: $true-white;
$body-color: $dark-blue;

/* Sessions */
$session-default: #f5a623;
$session-break: #8b572a;
$status-in: #207e36;
$status-out: #c8584c;
$status-pending: $true-white;
$status-pending-color: #145a90;
$status-accepted: $true-white;
$status-accepted-color: #207e36;
$status-canceled: #dddddd;
$status-rejected: #dddddd;
$status-current: $primary;
$status-current-color: $true-white;

/* Cookies banner */
$cookies-bg: $primary;
$cookies-btn: darken($primary,10%);
$cookies-more: $tertiary;

/* Popover */
$popover-bg: $primary;
$popover-txt: $true-white;

$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "light": $light,
        "tertiary": $tertiary,
        "quaternary": $quaternary
);

@each $color, $value in $theme-colors {
    .bg-#{$color} {
        background-color: $value!important;
    }
}

/* Content palette */
$c1-bg: $primary;
$c1-heading: $true-white;
$c1-secondary-heading: $true-white;
$c1-text: $true-white;
$c1-li: $true-white;
$c1-link: $true-white;
$c1-link-hover: $true-white;
$c1-border: $true-white;
$c1-highlight-bg: $true-white;
$c1-highlight-text: $primary;

$c2-bg: $secondary;
$c2-heading: $true-white;
$c2-secondary-heading: $true-white;
$c2-text: $true-white;
$c2-li: $true-white;
$c2-link: $true-white;
$c2-link-hover: $true-white;
$c2-border: $true-white;
$c2-highlight-bg: $true-white;
$c2-highlight-text: $secondary;

$c3-bg: $true-white;
$c3-heading: $primary;
$c3-secondary-heading: $true-black;
$c3-text: $true-black;
$c3-li: $true-black;
$c3-link: $primary;
$c3-link-hover: $true-black;
$c3-border: $true-black;
$c3-highlight-bg: $true-white;
$c3-highlight-text: $primary;

$c4-bg: $tertiary;
$c4-heading: $primary;
$c4-secondary-heading: $primary;
$c4-text: $primary;
$c4-li: $primary;
$c4-link: $primary;
$c4-link-hover: $primary;
$c4-border: $primary;
$c4-highlight-bg: $primary;
$c4-highlight-text: $true-white;

$c1-btn-bg: $secondary;
$c1-btn-text: $true-white;
$c1-btn-borders: $secondary;
$c1-btn-bg-hover: darken($secondary,10%);
$c1-btn-text-hover: $true-white;
$c1-btn-borders-hover: darken($secondary,10%);

/*General Colors*/

$body-bg:$primary;
$header-bg:rgba($primary,0.8);
$header-text:$true-white;
$header-borders:$true-white;
$header-highlight:$true-white;
$main-navigation-link:$true-white;
$main-navigation-link-hover:$true-white;
$main-navigation-link-active:$true-white;
$main-navigation-dropdown:$primary;
$main-navigation-dropdown-link:$true-white;
$main-navigation-dropdown-link-hover:$true-white;
$main-navigation-dropdown-link-active:$true-white;
$secondary-navigation-link:$true-white;
$secondary-navigation-link-hover:$true-white;
$footer-bg:$primary;
$footer-heading:$true-white;
$footer-text:$true-white;
$footer-link:$true-white;
$footer-link-hover:$true-white;
$footer-borders:$true-white;
$footer-highlight:$true-white;

/*Base Content*/

$base-bg:$primary;
$base-heading:$true-white;
$base-secondary-heading:$true-white;
$base-text:$true-white;
$base-borders:$true-white;
$base-link:$true-white;
$base-link-hover:$true-white;
$base-highlight:$true-white;
$base-highlight-text:$primary;

/*Base Button*/

$base-btn-bg: $true-white;
$base-btn-borders:$primary;
$base-btn-text:$primary;
$base-btn-bg-hover:$primary;
$base-btn-borders-hover: $true-white;
$base-btn-text-hover:$true-white;

/* Base spacing */
$base-spacing:30px;
$base-half-spacing:15px;
$base-border-width:1px;
$base-border-radius:0;
$base-box-shadow:0 0 0 fade-in($true-black,0%);

/* Form fields */
$form-field-border-width:1px;
$form-field-border-radius:0;

/* Buttons */
$btn-border-width:1px;
$btn-border-radius:0;
$btn-box-shadow:0 0 0 fade-in($true-black,0%);

$btn-padding_top: 10px;
$btn-padding_right: 14px;
$btn-padding_bottom: 10px;
$btn-padding_left: 14px;

$btn_sm-padding_top: 8px;
$btn_sm-padding_right: 12px;
$btn_sm-padding_bottom: 8px;
$btn_sm-padding_left: 12px;

$btn_lg-padding_top: 12px;
$btn_lg-padding_right: 16px;
$btn_lg-padding_bottom: 12px;
$btn_lg-padding_left: 16px;

/* Header breakpoints */
$small-breakpoint: 991px;
$large-breakpoint: 992px;

/* Header sizes */
$small-header: 60px;
$large-header: 90px;
$xlarge-header: 140px;

$small-header-half: unquote("calc(#{$small-header} / 2)");
$large-header-half: unquote("calc(#{$large-header} / 2)");
$large-header-quarter: unquote("calc(#{$large-header} / 4)");

/* Header on scroll size */
$header-on-scroll: 90px;
$xlarge-header-on-scroll: 140px;

/* Main navigation sizes */
$nav-main-height:90px;
$nav-main-sub-height:90px;
$nav-main-dropdown-width:240px;
$nav-main-dropdown-width_wide:480px;

/* Component spacing */
$pc-spacing:30px;
$pc-spacing_sm:45px;
$pc-spacing_md:50px;
$pc-spacing_lg:60px;
$pc-spacing_xl:80px;

/* Banner heights */
$takeover-banner: 100vh;
$medium-banner: 65vh;
$medium-bannerPX: 450px;
$short-banner: 50vh;
$short-bannerPX: 340px;

/* Slick */
$slick-arrow: $true-white;
$slick-arrow-hover: $true-white;

// Shadox
$box-shadow: 0 2px 16px 0 rgb(17 48 126 / 10%);
$box-shadow-img: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1536px,
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer * .25),
                        2: ($spacer * .5),
                        3: $spacer,
                        4: ($spacer * 1.5),
                        5: ($spacer * 3)
                ),
                $spacers
);

$document-padding-h: 1.5rem;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

// Tables
$th-padding-v: 10px;
$th-padding-h: 10px;
$td-padding-v: 10px;
$td-padding-h: 10px;
$thead-th-color: $white;
$tbody-th-color: $white;
$th-bg: $primary;
$td-bg: $medium-grey;
$th-bg-alt: lighten($primary, 12%);
$td-bg-alt: $lighter-grey;
$th-border: 1px solid $primary;
$td-border: 1px solid $lighter-grey;

/*******/
/* CRM */
/*******/
// Spacing
$crm-padding-top: 160px;
$crm-padding-bottom: 145px;
$sp: 10px;

$h1-spacing: 70px;
$h1-spacing-mobile: 15px;

$crm-fieldset-padding-v: 40px;
$crm-fieldset-padding-h: 40px;
$crm-dashboard-padding-h: 15px;
$crm-dashboard-padding-v: 15px;

$crm-fieldset-margin-v: 30px;
$mobile-padding: 25px;
$crm-mobile-padding: 20px;
$crm-card-padding-v: 25px;
$crm-card-padding-h: 20px;
$crm-card-padding-mobile: 20px;
$crm-tabs-padding-v: 20px;
$crm-tabs-padding-h: 30px;
$main-bottom-spacing: 155px;
$section-spacing: 70px;
$section-spacing-mobile: 70px;
$grid-v-spacing-mobile: 30px;
$grid-v-spacing: 30px;

$ice: #ebecec;
$grey: #d3dee3;
$light-grey: #949fa5;
$very-light-grey: #fcfcfc;
$mist: #c5c7c8;
$dark-grey: #a4b1b7;
$pale-grey: #f3f6fc;
$charcoal-grey: #3e4549;
$charcoal-grey-30: lighten($charcoal-grey, 30%);
$yellow: #fc3;
$powder-blue: #b8e5f3;
$blue-medium: #2055a4;
$light-green: #8beab8;
$green: #18d672;
$green-excel: #217547;
$orangey-red: #ff2727;
$pearl: #53565a;
$red: #ff2727;
$charcoal: #1a1c1c;
$orange: #df4e04;
$mustard: #c89e15;
$lila: #00648d;
$euro: #006390;

$DarkGray: #484a46;
$LightGray: #aab5c7;
$Turquoise: #5cbcab;
$Red: #e83b4c;
$LightGreen: #89bd33;
$Violet: #594e9b;
$DarkBlue: #2c4d9c;
$Yellow: #fcc42b;
$LightBlue: #21a9e2;
$Orange: #f49b6b;

$LightGrayPale: #e6eaef;
$GreenPale: #7fe9b1;
$TurquoisePale: #99d6ca;
$RedPale: #f2929c;
$LightGreenPale: #c1e18e;
$VioletPale: #b4aed6;
$YellowPale: #fde39b;
$OrangePale: #f9ccb3;

// Grid gutter
$grid-gutter: 50px;
$half-grid-gutter: $grid-gutter/2;

$crm-grid-gutter: 30px;
$half-crm-grid-gutter: $crm-grid-gutter/2;